import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

import Logo from "./../assets/images/logo.png";
import Wallet from "./../assets/images/wallet.png";

function useStickyHeader(offset = 0) {
  const [stick, setStick] = useState(false);
  const handleScroll = () => {
    setStick(window.scrollY > offset);
  };
  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return stick;
}

function Header(props) {
  const ref = useRef();
  const sticky = useStickyHeader(50);
  const headerClasses = ` ${sticky ? "sticky" : ""}`;
  const [isOpen, setOpen] = React.useState(true);
  const { isConnect, account, connectWallet, disConnectWallet, chainId } =
    props;

  console.log("chainId :", chainId);
  return (
    <HeaderOuter className={headerClasses}>
      <Container className="flex">
        <HeaderInner>
          <LogoM>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </LogoM>

          <HeaderRight>
            <Link to="/">
              <span>Account:</span>
              {account ? (
                chainId == 5 ? (
                  account
                ) : (
                  <span style={{ color: "red", fontSize: "2rem" }}>
                    Switch to Goerli Network
                  </span>
                )
              ) : (
                "Connect Metamask"
              )}
            </Link>
            <Button
              to="/home"
              className={`btn ${!isConnect ? "connectBG" : "disconnectBG"}`}
              onClick={!isConnect ? connectWallet : disConnectWallet}
            >
              <img src={Wallet} />
              {!isConnect
                ? "Connect Wallet"
                : chainId == 5
                ? "Disconnect Wallet"
                : "Wrong Network"}
            </Button>
          </HeaderRight>
        </HeaderInner>
      </Container>
    </HeaderOuter>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const HeaderOuter = styled.header`
  transition: all 500ms;
  width: 100%;
  margin-bottom: 30px;
  padding: 14px 0;
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #212741;
  overflow: hidden;
  @media (max-width: 768px) {
    position: relative;
  }
`;
const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-flow: column;
  }
`;
const LogoM = styled.div`
  min-width: 136px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 50px);
    top: 20px;
    height: 50px;
    width: 200px;
    background: #ffffff;
    z-index: -1;
    filter: blur(20px);
    transform: scale(3);
    transform-origin: top;
    opacity: 0.15;
  }
  @media (max-width: 640px) {
    min-width: inherit;
    width: 136px;
  }
`;
const HeaderRight = styled(FlexDiv)`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 50px);
    top: 20px;
    height: 50px;
    width: 200px;
    background: #ffffff;
    z-index: -1;
    filter: blur(20px);
    transform: scale(3);
    transform-origin: top;
    opacity: 0.15;
  }
  a:not(.btn) {
    color: var(--text-color);
    margin-right: 38px;
    color: #babbbf;
    font-size: 1.8rem;
    span {
      color: #ffffff;
      font-size: 2.4rem;
      margin-right: 10px;
      font-weight: 700;
    }
  }
  @media (max-width: 1024px) {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
  @media (max-width: 640px) {
    a:not(.btn) {
      display: none;
    }
  }
`;
const Button = styled.a`
  min-width: 260px;
  text-align: center;
  background: linear-gradient(90deg, #018f3f, #338a4c);
  color: #fff;
  border-radius: 5px;
  padding: 17px 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 600;
  &:hover {
    box-shadow: 0 0 0 rgba(30, 80, 255, 0.8);
  }
  border-radius: 50px;
  img {
    height: 20px;
    margin-right: 9px;
  }

  @media (max-width: 768px) {
    min-width: inherit;
    padding: 13px 22px;
  }
`;
const Container = styled.div`
  width: 1360px;
  margin: 0px auto;
  max-width: 100%;
  padding: 0 15px;
  display: flex;
  flex-flow: wrap;
  @media only screen and (max-width: 1200px) {
    width: 992px;
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  /* @media only screen and (max-width: 1400px) {
        width: 1190px;
    } */
`;
export default Header;
