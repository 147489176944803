import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import Media from "./../theme/media-breackpoint";

var Gs = {};

Gs.GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0; 
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight:400 ;
  }  
  button{ outline:none; border:none;}
  .connectBG {
    background: linear-gradient(90deg, #018fff, #0171c9);
  }
  .disconnectBG {
    background: linear-gradient(90deg, #018f3f, #338a4c);
  }
  .collapse-css-transition { transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .app__collapse{ visibility:hidden; opacity:0;}
  .app__collapse.collapse-active{ visibility:visible; opacity:1;}


  .Show-sm{ display:none !important; } 
  .youtube-embed {
    position: relative;
    padding-bottom: 56%;
    width: 100%;
    text-align: left;
  }

  .youtube-embed iframe {
    width: 100%;
    position: absolute;
    height: 100%;
    overflow: hidden;
  }


@media(max-width:767px){ 
  .Show-lg{ display:none !important; }
  .Show-sm{ display:flex !important; }
} 

`;

Gs.Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0;
`;

export default Gs;
