import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Header from "./component/header";
import Web3Modal from "web3modal";
import axios from "axios";
import Home from "./pages/home";
import Gs from "./theme/globalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme/theme";
import Web3 from "web3";

let web3Modal = new Web3Modal({
  network: "testnet",
  cacheProvider: true,
  providerOptions: {},
});
function App() {
  const [isDark, setDarkTheme] = useState(true);
  const selectedTheme = theme(isDark);

  function setTheme(flag) {
    setDarkTheme(flag);
  }

  return (
    <Router>
      <ThemeProvider theme={selectedTheme}>
        <section className="bodySection clearfix">
          <Gs.GlobalStyle />

          <Switch>
            <Route path="/" exact>
              {" "}
              <Home isDarkTheme={isDark} />{" "}
            </Route>
          </Switch>
        </section>
      </ThemeProvider>
    </Router>
  );
}

const RightBX = styled.div`
  width: calc(100% - 240px);
  background-image: url("${(props) => props.theme.RBXbg}");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  min-height: 100vh;
`;
export default App;
