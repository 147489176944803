import { ethers } from "ethers";
import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import swap from "./../assets/images/swap.png";
import BUSD_abi from "../ABI/BUSD_abi.json";
import Web3Modal from "web3modal";
import axios from "axios";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LogDescription } from "ethers/lib/utils";
import Header from "../component/header";
import { theme } from "../theme/theme";
let web3Modal = new Web3Modal({
  network: "testnet",
  cacheProvider: true,
  providerOptions: {},
});
// let web3 = new Web3(new Web3.givenProvider());

let BUSDIntervalID = null;

const Home = (props) => {
  const toastId = React.useRef(null);
  const [dbData, setDbData] = useState([]);
  const [list, setList] = useState([]);
  const [BUSDbalance, setBUSDbalance] = useState(0);
  const [inr, setInr] = useState("");
  const [busd, setBusd] = useState(inr * 5);
  const [reload, shouldReload] = useState(false);
  const [convertSwitch, setConvertSwitch] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonLoader1, setButtonLoader1] = useState(false);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState();
  const [account, setAccount] = useState(null);
  const [toggle, setToggle] = useState("mint");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [isConnect, setConnect] = useState(false);
  const [balance, setBalance] = useState("");
  const [isDark, setDarkTheme] = useState(true);
  const selectedTheme = theme(isDark);

  function setTheme(flag) {
    setDarkTheme(flag);
  }
  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect("walletconnect");
      const library = new ethers.providers.Web3Provider(provider);
      const signer = await library.getSigner();
      localStorage.setItem("modalProvider", 1);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      let accBalance = await signer.getBalance();
      accBalance = ethers.utils.formatEther(accBalance);
      setBalance(accBalance);
      setProvider(provider);
      setSigner(signer);
      if (accounts) {
        let account = await Web3.utils.toChecksumAddress(accounts[0]);
        setAccount(account);
        setConnect(true);
      }
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = async () => {
    setAccount();
    setChainId();
    setBalance();
    setBUSDbalance(0);
    // setNetwork("");
  };
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("modalProvider")) await connectWallet();
    })();
  }, []);
  const disConnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.removeItem("modalProvider");
    if (BUSDIntervalID) {
      await clearInterval(BUSDIntervalID);
      BUSDIntervalID = await null;
    }
    refreshState();
    setConnect(false);
  };

  //   useEffect(() => {
  //     if (!web3Modal.cachedProvider) {
  //       connectWallet();
  //     }
  //   }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = async (accounts) => {
        connectWallet();
        await getList();
        // console.log("accountsChanged", accounts);

        if (accounts) {
          let account = await Web3.utils.toChecksumAddress(accounts[0]);
          setAccount(account);
        }
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        // console.log("disconnect", error);
        disConnectWallet();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);
  // useEffect(() => {
  //   let url = `https://musdapi.minddeft.com/api/payuser/address/${account}?burnTransaction=${
  //     toggle == "burn" ? true : false
  //   }`;
  //   axios
  //     .get(url)
  //     .then(async (response) => {
  //       if (response?.status === 200) {
  //         await setList(response?.data);
  //         await getBUSDBalance(account);

  //         console.log("response.data.data", response.data);
  //       }
  //     })
  //     .catch(async (error) => {
  //       await setList([]);
  //       await getBUSDBalance(account);

  //       console.log("error.message", error);
  //     });
  // }, [toggle]);
  const getList = async () => {
    if (account) {
      if (BUSDIntervalID) {
        await clearInterval(BUSDIntervalID);
        BUSDIntervalID = await null;
      }
      console.log("Account", account);
      let url = `https://musdapi.minddeft.com/api/payuser/address/${account}?burnTransaction=${
        toggle == "burn" ? true : false
      }`;
      // let url = `https://musdapi.minddeft.com/api/payuser/address/${account}`;
      axios
        .get(url)
        .then(async (response) => {
          if (response?.status === 200) {
            await setList(response?.data);
            await getBUSDBalance(account);

            // console.log("response", response);
          }
        })
        .catch(async (error) => {
          await setList([]);
          await getBUSDBalance(account);

          console.log("error.message", error);
        });
      // const response = await axios.get(url);
      // console.log("response", response);

      // console.log("response", response.data, typeof response);
      // console.log("response", list.data, list.balance);
    } else {
      setList([]);
    }
  };
  useEffect(() => {
    getList();
    setInr("");
    setBusd("");
    if (account) getBUSDBalance(account);
  }, [account, dbData, toggle]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    try {
      const header = {
        "api-key":
          "da3f89789b06fa0c5c3be65e5e18a7fafdda6bcdb51db9fe2b821c634c042405",
        "content-type": "application/json",
      };
      if (!account) toast.error("Please connect your wallet first");
      else {
        if (data.get("amount") <= 0)
          toast.error("Amount must be greater than zero");
        if (data.get("amount") > 0) {
          let url = `https://musdapi.minddeft.com/api/payuser/add-money`;
          const sendRequest = await axios.post(url, {
            address: account,
            balance: data.get("amount"),
          });
          if (sendRequest.status === 200) {
            setDbData(sendRequest.data);
            getList();
            //await getBUSDBalance(account);
            await toast.success("Now you can Mint INR!");
            // await setWaitmsg("save data successfully  <----> You can Mint INR!");
            // console.log(sendRequest);
            // alertOff();
          }
        }
      }
    } catch (e) {
      toast.error("Transaction failed");
      console.log(e);
    }
  };

  const getClaim = async (e) => {
    e.preventDefault();
    try {
      if (chainId != 5) toast.error("Please Switch to a Goerli Network");
      if (+inr <= 0) toast.error("INR must be greater than zero");
      if (+inr > +list?.balance)
        toast.error("Not enough INR balance for Mint BUSD");
      if (+inr > 0 && inr <= +list?.balance && chainId == 5) {
        await setButtonLoader(true);
        if (BUSDIntervalID) {
          await clearInterval(BUSDIntervalID);
          BUSDIntervalID = await null;
        }
        let url = `https://musdapi.minddeft.com/api/payuser/add-pay`;
        // console.log("call1", inr);
        await axios
          .post(url, {
            amount: inr,
            address: account,
          })
          .then(async (resp) => {
            const provider = await new ethers.providers.Web3Provider(
              window.ethereum
            );
            await provider.send("eth_requestAccounts", []);
            const signer = await provider.getSigner();
            const contract = await new ethers.Contract(
              "0xF0D384482A32C5f9EA53354869BA9A98c1d9AbB2",
              BUSD_abi,
              signer
            );
            // console.log("contract", contract);
            // console.log("id", resp.data.id);
            const claimBal = await contract.requestBytes(`${resp.data.id}`);
            const isWaiting = claimBal.wait();
            if (isWaiting) {
              toastId.current = await toast(
                "Mint BUSD processing, please wait...",
                {
                  autoClose: false,
                  closeButton: false,
                }
              );
              // await toast.info("claimable amount is in waiting....");
            }
            if (await isWaiting) {
              // console.log("claimBal", claimBal);
              getList();
              // setWaitmsg("claimable amount is successfull");
              if (claimBal.hash) {
                let url = `https://musdapi.minddeft.com/api/payuser/transaction-success/${resp.data.id}`;
                const response = await axios.get(url);
                // setId(true);
                setDbData([]);
                //await getBUSDBalance(account);
                setButtonLoader(false);
                // console.log("toastId1", toastId.current);
                await toast.update(toastId.current, {
                  render: (
                    <>
                      claimable amount is successfull!
                      <br />
                      Check your transaction{" "}
                      <a
                        href={`https://goerli.etherscan.io/tx/${claimBal.hash}`}
                        target={"_blank"}
                      >
                        click here
                      </a>
                    </>
                  ),
                  type: toast.TYPE.SUCCESS,
                  autoClose: 8000,
                });
                toastId.current = await null;
                // console.log("toastId2", toastId);
                // await toast.success(
                //   `claimable amount is successfull!\nCheck your transaction here`,
                //   {
                //     onClick: () =>
                //       window.open(
                //         `https://goerli.etherscan.io/tx/${claimBal.hash}`
                //       ),
                //   }
                // );
                // await setTxnHash(claimBal.hash);
                // getBUSDBalance(account);
                // if (claimBal?.hash)
                //   setTimeout(() => {
                //     getBUSDBalance(account);
                //   }, 1500);
                // alertOff();
                // console.log("claimBal", response);
              }
            }
          });
      }
    } catch (e) {
      // console.log("toastId1", toastId.current);
      !toastId.current && toast.error("Transaction failed");
      toastId.current &&
        toast.update(toastId.current, {
          render: (
            <>
              Transaction failed !
              <br />
            </>
          ),
          type: toast.TYPE.ERROR,
          autoClose: 8000,
        });
      toastId.current = await null;
      getList();
      //await getBUSDBalance(account);
      setButtonLoader(false);
      console.log("error", e);
    }
  };

  const getBUSDBalance = async (account) => {
    console.log("BUSDIntervalID: 1 =>", account);
    try {
      if (!BUSDIntervalID) {
        BUSDIntervalID = await setInterval(async () => {
          console.log("BUSDIntervalID: 2 =>", account);
          const provider = await new ethers.providers.Web3Provider(
            window.ethereum
          );
          await provider.send("eth_requestAccounts", []);
          const signer = await provider.getSigner();
          const contract = await new ethers.Contract(
            "0xF0D384482A32C5f9EA53354869BA9A98c1d9AbB2",
            BUSD_abi,
            signer
          );
          let hexNumber = await contract.balanceOf(account);
          let BUSD_b = await String(hexNumber._hex);
          console.log("BUSD", Web3.utils.fromWei(BUSD_b, "ether"));
          if (BUSDbalance != Web3.utils.fromWei(BUSD_b, "ether")) {
            await clearInterval(BUSDIntervalID);
            BUSDIntervalID = await null;
            getList();
            setBUSDbalance(Web3.utils.fromWei(BUSD_b, "ether"));
          }
        }, 1000);
      }
    } catch (error) {
      toast.error("Transaction failed");
    }
  };

  const burnBUSD = async () => {
    try {
      if (chainId != 5) toast.error("Please Switch to a Goerli Network");
      if (busd <= 0) toast.error("BUSD must be greater than zero");
      // if (busd == 0) window.alert("Enter BUSD amount...");
      // console.log("busd", busd, "BUSDbalance", BUSDbalance);
      if (+busd > +BUSDbalance)
        toast.error("BUSD value greater than BUSD Balance.");

      if (+BUSDbalance >= +busd && +busd > 0 && chainId == 5) {
        setButtonLoader(true);
        const provider = await new ethers.providers.Web3Provider(
          window.ethereum
        );
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contract = await new ethers.Contract(
          "0xF0D384482A32C5f9EA53354869BA9A98c1d9AbB2",
          BUSD_abi,
          signer
        );

        const burnBUSDToken = await contract.burn(
          Web3.utils.toWei(busd, "ether")
        );
        const isWaiting = burnBUSDToken.wait();
        if (isWaiting) {
          // setId(true);
          toastId.current = await toast(
            "Burn BUSD processing, please wait...",
            {
              autoClose: false,
              closeButton: false,
            }
          );
          // toast.info("BUSD Burning is in waiting....");
        }
        if (await isWaiting) {
          setDbData([]);
          getList();
          //await getBUSDBalance(account);
          await toast.update(toastId.current, {
            render: (
              <>
                Burn BUSD successfull!
                <br />
                Check your transaction{" "}
                <a
                  href={`https://goerli.etherscan.io/tx/${burnBUSDToken.hash}`}
                  target={"_blank"}
                >
                  click here
                </a>
              </>
            ),
            type: toast.TYPE.SUCCESS,
            autoClose: 8000,
          });
          toastId.current = await null;
          // await toast.success(
          //   `Burn BUSD successfull!\nCheck your transaction here`,
          //   {
          //     onClick: () =>
          //       window.open(
          //         `https://goerli.etherscan.io/tx/${burnBUSDToken.hash}`
          //       ),
          //   }
          // );
          setButtonLoader(false);
          // await setTxnHash(burnBUSDToken.hash);
          // getBUSDBalance(account);
          // if (burnBUSDToken?.hash)
          //   setTimeout(() => {
          //     getBUSDBalance(account);
          //   }, 1500);
          // alertOff();
        }
        // console.log("burnBUSDToken", burnBUSDToken);
      }
    } catch (e) {
      !toastId.current && toast.error("Transaction failed");
      toastId.current &&
        toast.update(toastId.current, {
          render: (
            <>
              Transaction failed !
              <br />
            </>
          ),
          type: toast.TYPE.ERROR,
          autoClose: 8000,
        });
      toastId.current = await null;
      console.log("error", e);
      setButtonLoader(false);
    }
  };

  const claimBUSD = async (claimid) => {
    if (BUSDIntervalID) {
      await clearInterval(BUSDIntervalID);
      BUSDIntervalID = await null;
    }
    try {
      if (chainId != 5) toast.error("Please Switch to a Goerli Network");
      else {
        setButtonLoader1(true);
        const provider = await new ethers.providers.Web3Provider(
          window.ethereum
        );
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contract = await new ethers.Contract(
          "0xF0D384482A32C5f9EA53354869BA9A98c1d9AbB2",
          BUSD_abi,
          signer
        );
        // console.log("contract", contract);
        // console.log("id", claimid);
        const claimBal = await contract.requestBytes(claimid);
        const isWaiting = claimBal.wait();
        if (isWaiting) {
          // setId(true);
          // await setTxnHash("");
          toastId.current = await toast(
            "Claim BUSD processing, please wait...",
            {
              autoClose: false,
              closeButton: false,
            }
          );
          // await toast.info("claimable amount is in waiting....");
        }
        if (await isWaiting) {
          // setId(true);
          getList();
          //await getBUSDBalance(account);
          // setWaitmsg("claimable amount is successfull");
          if (claimBal.hash) {
            let url = `https://musdapi.minddeft.com/api/payuser/transaction-success/${claimid}`;
            const response = await axios.get(url);
            // setId(true);
            setDbData([]);
            setButtonLoader1(false);
            // await setTxnHash(claimBal.hash);
            await toast.update(toastId.current, {
              render: (
                <>
                  claimable amount is successfull!
                  <br />
                  Check your transaction{" "}
                  <a
                    href={`https://goerli.etherscan.io/tx/${claimBal.hash}`}
                    target={"_blank"}
                  >
                    click here
                  </a>
                </>
              ),
              type: toast.TYPE.SUCCESS,
              autoClose: 8000,
            });
            toastId.current = await null;
            // await toast.success(
            //   `claimable amount is successfull!\nCheck your transaction here`,
            //   {
            //     onClick: () =>
            //       window.open(`https://goerli.etherscan.io/tx/${claimBal.hash}`),
            //   }
            // );
            // if (claimBal?.hash)
            //   setTimeout(() => {
            //     getBUSDBalance(account);
            //   }, 1500);
            // alertOff();
            // console.log("claimBal", response);
          }
          // setDbData([]);
          // getBUSDBalance(account);
          // setTxnHash(claimBal.hash);
          // alertOff();
        }
      }
      // console.log("claimBal", claimBal);
    } catch (e) {
      !toastId.current && toast.error("Transaction failed");
      toastId.current &&
        toast.update(toastId.current, {
          render: (
            <>
              Transaction failed !
              <br />
            </>
          ),
          type: toast.TYPE.ERROR,
          autoClose: 8000,
        });
      toastId.current = await null;
      // getBUSDBalance(account);
      setButtonLoader1(false);
      getList();
      //await getBUSDBalance(account);
    }
  };
  const handleInr = (e) => {
    const data = e.target.value;
    setInr(data);
    setBusd(data ? data / 5 : "");
  };
  const handleBusd = (e) => {
    const data = e.target.value;
    setBusd(data);
    setInr(data ? data * 5 : "");
  };

  console.log("Toggle busd", toggle, list);
  return (
    <>
      <Header
        isDarkTheme={isDark}
        setTheme={setTheme}
        isConnect={isConnect}
        chainId={chainId}
        account={account}
        connectWallet={connectWallet}
        disConnectWallet={disConnectWallet}
      />
      <Container>
        <HomeCards>
          <Card1>
            <p>
              Wallet Balance: <span>{account ? balance : 0}</span>
            </p>
            <p>
              Borrow Capacity:{" "}
              <span>{list.balance === undefined ? 10000 : 0}</span>
            </p>
            <p>
              INR Balance:{" "}
              <span>{list.balance === undefined ? 0 : +list.balance} INR</span>
            </p>
            <p>
              BUSD Balance: <span>{account ? BUSDbalance : 0} BUSD</span>
            </p>
            <p>
              Ratio of INR to BUSD: <span>5:1</span>
            </p>
          </Card1>
          {console.log("list balance", list.balance)}
          {list.balance === undefined ? (
            <Card2>
              <form className="m-2" onSubmit={handleSubmit}>
                <h3>Borrow INR!!</h3>
                <TextBox>
                  <input
                    type="text"
                    placeholder="Enter amount in INR"
                    name="amount"
                  />
                </TextBox>
                <Button type="submit" id="borrow">
                  {"Borrow INR"}
                </Button>
                {/* <h3>Borrow INR!!</h3>
					<TextBox>
						<input type="text" placeholder='Enter amount in INR' name="wallet_ballance" />
						<span>INR Max</span>
					</TextBox> */}
              </form>
            </Card2>
          ) : (
            <Card2>
              <div className="textarea">
                <label>
                  {convertSwitch ? "BUSD Amount:" : "Wallet Balance:"}
                </label>
                <TextBox>
                  <input
                    value={
                      convertSwitch
                        ? busd >= 0
                          ? busd
                          : ""
                        : inr >= 0
                        ? inr
                        : ""
                    }
                    type="number"
                    placeholder={
                      convertSwitch
                        ? "Enter amount in BUSD"
                        : "Enter amount in INR"
                    }
                    name={convertSwitch ? "BUSD" : "INR"}
                    onChange={convertSwitch ? handleBusd : handleInr}
                  />
                  <span
                    onClick={
                      convertSwitch
                        ? () => {
                            setBusd(BUSDbalance);
                            setInr(+BUSDbalance * 5);
                          }
                        : () => {
                            setInr(+list.balance);
                            setBusd(+list.balance / 5);
                          }
                    }
                  >
                    {convertSwitch ? "BUSD Max" : "INR Max"}
                  </span>
                </TextBox>
              </div>
              <div className="swap">
                <a onClick={() => setConvertSwitch(!convertSwitch)}>
                  <img src={swap}></img>
                </a>
              </div>
              <div className="textarea">
                <label>
                  {!convertSwitch ? "BUSD Amount:" : "Wallet Balance:"}
                </label>
                <TextBox>
                  <input
                    placeholder={
                      !convertSwitch
                        ? "Enter amount in BUSD"
                        : "Enter amount in INR"
                    }
                    value={
                      !convertSwitch
                        ? busd > 0
                          ? busd
                          : ""
                        : inr > 0
                        ? inr
                        : ""
                    }
                    type="number"
                    name={!convertSwitch ? "BUSD" : "INR"}
                    onChange={!convertSwitch ? handleBusd : handleInr}
                    readOnly
                  />
                </TextBox>
              </div>
              <Button
                disabled={buttonLoader}
                onClick={convertSwitch ? burnBUSD : getClaim}
              >
                {buttonLoader ? (
                  <span>Loading...</span>
                ) : convertSwitch ? (
                  "Burn BUSD!"
                ) : (
                  "Mint BUSD!"
                )}
              </Button>
              {/* <h3>Borrow INR!!</h3>
					<TextBox>
						<input type="text" placeholder='Enter amount in INR' name="wallet_ballance" />
						<span>INR Max</span>
					</TextBox> */}
            </Card2>
          )}
        </HomeCards>
        <Toggle>
          <div className="toggleLayout">
            <button
              className={`${toggle == "mint" ? "active" : ""}`}
              onClick={() => setToggle("mint")}
            >
              Mint Transactions
            </button>
            <button
              className={`${toggle == "burn" ? "active" : ""}`}
              onClick={() => setToggle("burn")}
            >
              Burn Transactions
            </button>
          </div>
        </Toggle>
        <HomeTable>
          {list?.data?.length > 0 && (
            <table>
              <thead>
                <tr>
                  <td>S No.</td>
                  <td>Owner Address</td>
                  <td>{"INR Amount"}</td>
                  <td>{toggle == "mint" ? "Minted BUSD" : "Burned BUSD"}</td>
                  <td>Date & Time(UTC)</td>
                  {toggle == "mint" ? <td>Action</td> : ""}
                </tr>
              </thead>
              <tbody>
                {list?.data?.map((item, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{item.address}</td>
                    <td>{item.amount}</td>
                    <td>{item.amount / 5}</td>
                    {/* <td>{item.id} </td> */}

                    <td>
                      {item.updatedAt.split("T")[0] +
                        " (" +
                        item.updatedAt.split("T")[1].split(".")[0] +
                        ")"}{" "}
                    </td>

                    {toggle == "mint" ? (
                      <td>
                        <button
                          disabled={
                            item.status === "true"
                              ? true
                              : +list.balance < +item.amount
                              ? true
                              : false
                          }
                          className={`btn ${
                            item.status === "true"
                              ? "disabled"
                              : +list.balance < +item.amount
                              ? "red"
                              : ""
                          }`}
                          onClick={() => claimBUSD(item.id)}
                        >
                          {item.status === "true"
                            ? "Already Claimed"
                            : +list.balance < +item.amount
                            ? "Not Enough INR Balance"
                            : "Claim BUSD"}
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
                {/* <tr>
                  <td>2</td>
                  <td>0dasf8grasf2ghafh65113asfgr8</td>
                  <td>115</td>
                  <td>63eb3687406fbb6e28ea2313 </td>
                  <td>2023-02-14 (07:21:43) </td>
                  <td>
                    <button className="btn">Claim BUSD</button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>0dasf8grasf2ghafh65113asfgr8</td>
                  <td>115</td>
                  <td>63eb3687406fbb6e28ea2313 </td>
                  <td>2023-02-14 (07:21:43) </td>
                  <td>
                    <button className="btn">Claim BUSD</button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>0dasf8grasf2ghafh65113asfgr8</td>
                  <td>115</td>
                  <td>63eb3687406fbb6e28ea2313 </td>
                  <td>2023-02-14 (07:21:43) </td>
                  <td>
                    <button className="btn disabled">Claim BUSD</button>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>0dasf8grasf2ghafh65113asfgr8</td>
                  <td>115</td>
                  <td>63eb3687406fbb6e28ea2313 </td>
                  <td>2023-02-14 (07:21:43) </td>
                  <td>
                    <button className="btn red">Claim BUSD</button>
                  </td>
                </tr> */}
              </tbody>
            </table>
          )}
        </HomeTable>
      </Container>
      <ToastContainer
        toastClassName={"bg-dark mt-3"}
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
const Toggle = styled.div`
  width: 100%;
  height: 50px;
  margin: 80px 0px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .toggleLayout {
    border-radius: 50px !important;
    padding: 8px 0px;
    margin: 0px;
    border: 1px solid white !important;
    button {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.8rem;
      border-radius: 50px;
      margin: 0px 10px 0;
      padding: 20px 20px;
      // display: table;
      background-color: transparent;
      width: 330px;
      max-width: 100%;
      cursor: pointer;
    }
    .active {
      background: linear-gradient(90deg, #018fff, #0171c9);
    }
  }
`;
const ToggleButton = styled.button`
  color: #ffffff;
  font-weight: 600;
  font-size: 1.8rem;
  border-radius: 50px;
  margin: 40px 10px 0;
  padding: 20px 20px;
  // display: table;
  background-color: transparent;
  width: 330px;
  max-width: 100%;
  cursor: pointer;
  &active {
    background: linear-gradient(90deg, #018fff, #0171c9);
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 12px 30px;
    margin-top: 30px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const HomeCards = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  @media (max-width: 768px) {
    flex-flow: column;
  }
`;
const Card1 = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  border: 0 solid #121832;
  width: 40%;
  padding: 50px;
  box-shadow: 0 0 5px #ffffff20;
  background: linear-gradient(45deg, #31406750, #0f2c5480);
  flex-grow: 1;
  p {
    border-bottom: 1px solid #55596b;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    font-size: 2.4rem;
    font-weight: 700;
    &:last-child {
      border-bottom: 0;
    }
    span {
      padding: 5px 28px;
      background: #cccccc;
      border-radius: 30px;
      font-size: 1.8rem;
      font-weight: 400;
    }
    &:nth-child(1) {
      span {
        background: #3cc0bb;
      }
    }
    &:nth-child(2) {
      span {
        background: #ecca0e;
      }
    }
    &:nth-child(3) {
      span {
        background: #65ca4e;
      }
    }
    &:nth-child(4) {
      span {
        background: #3ca6c0;
      }
    }
    &:nth-child(5) {
      span {
        background: #3c61c0;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 640px) {
    padding: 15px;
    p {
      font-size: 1.8rem;
      span {
        padding: 3px 15px;
      }
    }
  }
`;
const Card2 = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  border-radius: 10px;
  border: 0 solid #121832;
  width: 40%;
  padding: 30px 50px;
  box-shadow: 0 0 5px #ffffff20;
  background: linear-gradient(45deg, transparent, #8061fb30);
  flex-grow: 1;
  .textarea {
    label {
      margin-bottom: 18px;
      display: block;
      font-weight: 700;
      font-size: 2rem;
    }
  }
  .swap {
    text-align: center;
    margin: 18px 0;
  }
  h3 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 640px) {
    padding: 15px;
  }
`;
const Container = styled.div`
  width: 1360px;
  margin: 0px auto;
  max-width: 100%;
  padding: 0 15px;
  display: flex;
  flex-flow: wrap;
  @media only screen and (max-width: 1200px) {
    width: 992px;
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const TextBox = styled.div`
  width: 100%;
  position: relative;
  input {
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 10px;
    background: none;
    border: 1px solid #fff;
    font-size: 1.8rem;
    color: #babcc1;
    padding: 0 18px;
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background: #018dfb;
    border-radius: 0 10px 10px 0;
    padding: 12px 38px;
  }
  @media (max-width: 640px) {
    input {
      padding: 0 10px;
      font-size: 1.6rem;
    }
    span {
      padding: 12px 15px;
    }
  }
`;

const Button = styled.button`
  color: #ffffff;
  font-weight: 600;
  font-size: 1.8rem;
  background: linear-gradient(90deg, #018fff, #0171c9);
  border-radius: 50px;
  margin: 40px auto 0;
  padding: 20px 20px;
  display: table;
  width: 330px;
  max-width: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    width: auto;
    padding: 12px 30px;
    margin-top: 30px;
  }
`;
const HomeTable = styled.div`
  width: 100%;
  padding: 0px 0 10px;
  margin-bottom: 40px;
  overflow: auto;
  table {
    width: 100%;
    min-width: 1200px;
    td {
      padding: 18px 20px;
      text-align: center;
    }
    .btn {
      font-size: 1.8rem;
      color: #fff;
      padding: 7px 23px;
      border-radius: 50px;
      background: #269015;
      white-space: nowrap;
      cursor: pointer;
      &.disabled {
        background: #90958f;
      }
      &.red {
        background: #901515;
      }
    }
    thead {
    }
    tbody {
      tr {
        td {
        }
        &:nth-child(odd) {
          border: 0 solid transparent;
          td {
            background: #314067;
          }
        }
        &:nth-child(even) {
          border: 1px solid #5c6173;
          td {
            background: none; /* border-top: 1px solid #5c6173; border-bottom: 1px solid #5c6173; */
          }
        }
      }
    }
  }
`;

export default Home;
